body.layout-focused-form {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-around;
  height: 100%;
  min-height: 100vh;

  font-size: 1rem !important;

  .focused-card {
    @extend .mb-3;
    border: 0;

    .card-logo {
      @extend .mb-3;
      .beta-tag {
        @extend .px-2;
        position: relative;
        transform: rotate(30deg);
        top: -10px;
        left: -20px;
        color: $white;
        border: 1px solid $white;
        background-color: $primary;
      }
    }

    h3 {
      @extend .h3;
      @extend .font-weight-bold;
    }
  }
  .side {
    @extend .mb-3
  }
}

div.content-container {
  div.row.homebuyer-hub-search-bar {
    form#search_form {
      input[type="text"] {
        background-color: white;
        color: $dark-blue !important;
        border: 1px solid $dark-blue;
        border-radius: 5px !important;
        padding: 2rem 1rem;
        &::placeholder {
          color: $dark-blue !important;
        }
      }
    }
  }
}
