.hq-progress-bar {
  .progress {
    @extend .mb-3;
    height: 5px;

    @for $i from 0 through 5 {
      &.value-#{$i * 20} {
        .progress-bar {
          width: #{$i * 20%};
        }
      }
    }

    .progress-bar {
      @extend .progress-bar-striped;
      @extend .progress-bar-animated;
    }
  }

  .progress-content {
    @extend .list-unstyled;

    li {
      text-align: center;

      &:not(.active) {
        display: none;
      }
    }
  }
}