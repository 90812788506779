body.address-report {
  .container {
    max-width: 900px;
  }
}

.address-report {
  @extend .reports;

  margin-bottom: 50px;

  h3 {
    @extend .text-uppercase;
    @extend .font-avenir-bold;
  }

  .report-banner {
      min-height: 85px;
      overflow-x: hidden;
      border-top: 0;
      position: relative;

      .banner-content {
          border: 3.5px solid #565759 !important;
          padding-top: 15px;
          padding-bottom: 10px;
      }

      h5.report-title {
        @extend .text-uppercase;
        @extend .mt-2;
        letter-spacing: 1px;
      }

      .home-pin {
          // position: absolute;
          // left: 40px;

          svg {
              width: 50px;
              height: 50px;
          }
      }
  }

  .hoverable-container {
    .expanded-info {
      a { display: block; margin: 0; }
    }
  }

  .hoverable-convenience {
    @extend .w-50;
    @extend .position-static;

    @include media-breakpoint-down(md) {
      width: 100% !important;
      padding-left: 15px;
    }

    .expanded-info {
      @extend .w-100;
      left: 0;
    }
  }

  .place-container {
    @include media-breakpoint-up(sm) {
      &:nth-of-type(2), &:nth-of-type(3) {
        border-left: 2px solid $gray-700;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 0 !important;

      &.convenience {
        .row {
          //margin-left: 0;
          margin-right: 0;
        }
      }
    }

    @media print {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  section.horizontal {
    @extend .row;
    @extend .mt-4;
    @extend .border-bottom;

    $headings-height: 32px;

    border-bottom-width: 2px !important;
    border-bottom-color: $gray-700 !important;

    .section-highlights {
      @extend .col-12;
      @extend .col-sm;

      @include media-breakpoint-down(md) {
        padding: 0 !important;

        div {
          padding: 0 !important;
        }
      }

      .graphic-container {
        @extend .col-12;
        @extend .d-none;
        @extend .d-sm-block;
        @extend .pl-0;
      }

      .heading-container {
        height: $headings-height;
        @extend .mb-2;

        h3 {
          @extend .text-uppercase;
          @extend .font-weight-bold;
        }
      }
    }

    &.schools {

      .school-column {
          .other-local-schools {
            @extend .font-weight-bold;
            @extend .mb-1;
            @extend .d-block;
            @extend .d-flex;
            @extend .align-items-center;
            margin-top: -8px;
            height: $headings-height;
            font-size: 17px;
            color: $gray-700;
          }
      }

      .section-highlights .heading-container h3 {
        @extend .text-schools;
      }

      .school-column {
        @extend .col-12;
        @extend .col-sm;

        @include media-breakpoint-up(lg) {
          &.padded-top {
            margin-top: 40px;
          }
        }

        @include media-breakpoint-down(md) {
          padding: 0 !important;
        }

        .look-deeper, .nearby-schools {
          font-size: 16px;
          @extend .font-weight-bold;
          @extend .mb-2;
          @extend .d-flex;
          @extend .align-items-center;
          @extend .d-print-none;

          @include media-breakpoint-down(md) {
            display: none !important;
          }
        }

        .look-deeper {
          height: $headings-height;

          .text-look {
            @extend .text-danger;
          }

          .text-deeper {
            color: $lookbook-blue-3;
          }

          .fa-share {
            @extend .ml-2;
            color: $lookbook-blue-3;
          }
        }

        .nearby-schools {
          color: $lookbook-blue-3;


          span {
            @extend .mr-2;
          }
        }

      }
    }

    &.parks {
      @extend .mt-2;

      .park-column {
        @extend .col-12;
        @extend .col-sm;

        margin-top: 20px;

        @include media-breakpoint-down(md) {
          padding: 0 !important;
        }

        h5.walkable-facilities {
          @extend .text-parks-secondary;
          @extend .text-uppercase;
          @extend .font-weight-bold;

          margin-top: 15px;
        }
      }
    }
  }

  div#home-shopping-tools {
    small {
      font-size: 60% !important;
      line-height: 10px !important;
    }

    a:hover {
      text-decoration: none !important;
    }

    .calculators {
      a:hover {
        text-decoration: underline !important;
      }
    }
    h3 {
      font-size: 1.3rem;
    }
    p {
      color: #878887;
    }
    p:nth-child(3) {
      position: relative;
      z-index: 2;
      margin-top: -0.7rem;
    }
    h5 {
      position: relative;
      z-index: 2;
      font-size: 1.2rem;
      color: #717271;
    }
    svg {
      position: relative;
      z-index: 1;
      margin-top: -1rem;
    }
  }

}

/***********************
 * PRINT STYLES
 ***********************/

@media print {

}