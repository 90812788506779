.new-session-container {
  margin-top: 18%;

  @media screen and (max-width: 991px) and (min-width: 451px) {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}

form#new_session {
  div#login-error {
    border: 1px solid red;
    border-radius: 1.3rem;
    color: red;
    text-align: center;
  }
  div.form-group {
    .is-invalid {
      border: 1px solid #c1c7cc;
    }
  }
}