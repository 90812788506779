.products-new, .products-create {

  @include media-breakpoint-up(lg) {
    .main-app-container {
      margin-top: 50px;
    }
  }

  .logo-container {
    @extend .col-12;
    @extend .col-md-4;
    @extend .offset-md-3;
    @extend .text-center;
    height: 135px;

    svg {
      width: 150px;
    }
  }

  .search-bar-container {
    @extend .col-12;
    @extend .col-md-8;
    @extend .offset-md-1;
    @extend .mt-5;
    @extend .text-center;
  }
}

div.row#search-address {
  margin-top: -6rem;
}