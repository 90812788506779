.mobileAgentBranding {
  height: 100%;
  width: 100%;

  /* margin-top: -25px; */
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .mobileAgentBranding > div {
    /* margin-bottom: 20px; */
  }
}

@media screen and (max-width: 767px) {
  .mobileAgentBranding > div {
    margin-bottom: -5px;
  }
}
