.users-new {

  .main-app-container {
    .nav-container {
      @extend .d-none;
    }

    .content-container {
      @extend .col-12;
      @extend .col-md-10;
      @extend .offset-md-1;
      @extend .mt-5;
      @extend .p-5;

      @include media-breakpoint-up(lg) {
        border: 2px solid $gray-700;
        border-radius: 60px;
        border-top-left-radius: 20px;
      }

      .no-plans .form-container {
        @extend .col-12;
        @extend .col-lg-8;
        @extend .offset-lg-2;
      }

      .form-container {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .text-center;

        .logo-container {
          @extend .w-100;
          @extend .mb-5;
          height: 135px;
  
          svg {
            width: 150px;
          }
        }
  
        h5 {
          @extend .mx-5;
        }
  
        .existing-user {
          @extend .mt-4;
        }
  
        form {
          @extend .mt-5;
  
          .StripeElement {
            @extend .form-control;
          }
  
          .secure {
            @extend .text-left;
            @extend .pl-2;
  
            span {
              @extend .ml-2;
            }
          }
  
          .btn {
            @extend .mt-5;
            @extend .w-100;
          }
  
          .terms {
            @extend .mt-3;
          }
        }
      }

      .plan-container {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .d-none;
        @extend .d-lg-flex;
        @extend .flex-column;

        &.mobile-only {
          @extend .d-flex;
          @extend .d-lg-none;
          //@extend .flex-row;
          @extend .text-left;
        }

        .plan-card {
          @extend .card;
          @extend .flex-fill;
          @extend .my-3;
          @extend .p-3;
          @extend .pt-5;

          @include media-breakpoint-down(md) {
            padding: 15px !important;
            font-size: 14px;
          
            ul.benefits {
              margin-top: 10px !important;
              padding-left: 5px !important;

              li {
                margin: 2px 0 !important;
              }
            }
          }

          cursor: pointer;
          color: $gray-500;

          font-size: 18px;

          &.active {
            border: 5px solid $primary;
            color: $gray-700;
          }

          .price {
            @extend .text-center;
            
            @include media-breakpoint-down(md) {
              font-size: 12px;
            }

            & > .amount {
              @extend .text-primary;
              @extend .font-avenir-bold;

              font-size: 2rem;

              @include media-breakpoint-down(md) {
                font-size: 1.25rem;
              }

              &.red {
                @extend .mr-1;
                font-size: 1.75rem;
                color: $red !important;
                text-decoration: line-through;

                @include media-breakpoint-down(md) {
                  font-size: 1rem;
                }
              }
            }

            .price-details {
              font-size: 14px;

              .amount.red {
                @extend .mr-1;
                color: $red !important;
                text-decoration: line-through;
              }
            }
          }

          .benefits {
            @extend .mt-5;

            li {
              @extend .my-3;
            }
          }
        }
      }
    }
  }
}
