@mixin facility-icon($name, $size) {
  background-image: map-get($facility-icons, $name);
  width:  $size;
  height: $size;
}

@mixin bordered-container($color, $padding: 10px, $border-size: 5px) {
  border:        $border-size solid $color;
  border-radius: 10px;
  padding:       $padding;
}

@mixin hoverable-content($color, $hover-padding: 10px, $background-color: #fff, $border-size: 5px) {
  position: relative;

  .regular-info.collapsing, .expanded-info.collapsing {
    transition: none;
  }

  .expanded-info {
    @extend .d-print-none;
  }

  @include media-breakpoint-up(lg) {
    &:hover .expanded-info {
      display: block;
    }

    .expanded-info {
      @include bordered-container($color, $hover-padding, $border-size);
      position: absolute;
      display: none;
      z-index: 90000;
      top: ($border-size + $hover-padding) * -1;
      left: ($border-size + $hover-padding) * -1;
      right: ($border-size + $hover-padding) * -1;

      background-color: $background-color;
    }
  }



  .regular-info {
    width: 100%;
  }
}

@mixin ul-with-image-bullets($height, $width, $url) {
  list-style:  none;
  padding-left: $width;

  li:before {
    display:           inline-block;
    content:           '';
    height:            $height;
    width:             $width;
    background-image:  $url;
    background-size:   contain;
    background-repeat: no-repeat;
    margin-left:       $width * -1;
    margin-bottom:     $height * -0.25;
  }

  li {
    margin-top: $height * 0.25;
  }
}