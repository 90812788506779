body.school-report {
  .container {
    max-width: 900px;
  }
}

.school-report {
  @extend .reports;

  padding-top: 20px;

  h1 {
    @extend .text-uppercase;
    color: $schools;
  }

  h3 {
    @extend .font-avenir-regular;
    font-weight: 700;
  }

  h4 {
    margin-top:    10px;
    margin-bottom: 10px;
  }

  .banner-content {
    .header-content {
      font-size: 18px;
      @extend .p-0;
    }

    .school-icon {
      @extend .d-flex;
      @extend .justify-content-center;

      img {
        width: 100px;
        height: 100px;
        margin-top: -6px;
      }
    }
  }
}

img[alt="Apple & Book"] {
  height: 120px;
}