$hoodq-f3f3f3: #f3f3f3;
$hoodq-565759: #565759;

@mixin safari-hoodq-login-shared {
  letter-spacing: 1px;
  font-size: 0.7rem;
}

@mixin hoodq-logo-shared {
  svg#hoodq-logo {
    position: relative;
    top: 0.5rem;
    left: -3.2rem;
  }
}


.app-nav {
  li {
    .nav-link {
      color: #54585A !important;
    }

    a, .share-button {
      color: #54585A !important;
      font-family: AvenirNextLTPro-Regular !important;
      letter-spacing: 1px;
      padding: 8px 15px 6px;
      border-radius: 30px;
      text-decoration: none;
      cursor: pointer;
      display: block;

      &:hover {
        background-color: $gray-100;
      }
      
      @media screen and (max-width: 767px) {
        //color: #fff !important;
        border-radius: 0px;
      }
    }

    .share-button {
      @extend .d-flex;
      @extend .flex-row;

      .share {
        flex: 0 0 60%;
        max-width: 60%;
      }

      .social-links {
        flex: 0 0 40%;
        max-width: 40%;
        @extend .d-flex;
        @extend .justify-content-between;
      }

      a {
        padding: 0px;
      }
    }
  }
}

.hq-nav li a {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: normal;
}

div.row#main-row {
  div.nav-container {
    a#hoodq-login {
      color: black !important;
      font-size: 0.8rem;
      font-weight: bold;
      background-color: #b7d953;
      border-radius: 0.5rem;
    }
  }
}

svg#hoodq-logo-no-usr {
  margin-top: 0;
  margin-left: -3.4rem;
}

svg#hoodq-logo, svg#hoodq-logo-report, svg#hoodq-logo-search-address {
  margin-top: 1.5rem;
  margin-left: -6rem;
}

svg#hoodq-logo-report {
  margin-left: -0.6rem;
}

svg#hoodq-logo-search-address {
  margin-top: -0.7rem;
}

/* Only supporting latest three versions of Safari (13.1, 14.1 and 15.6), but keep older fixes for now since they were worked out */
/* Bootstrap grid does not work on Safari 8 and earlier */

/* Works to target Safari 14.1+ */
@supports(font:-apple-system-body) and (not(-webkit-touch-callout:default)){
  a#hoodq-login {
    @include safari-hoodq-login-shared;
  }
  @include hoodq-logo-shared;
}

/* Safari 11 to 15.6 - Needed for 13.1 */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  a#hoodq-login {
    @include safari-hoodq-login-shared;
  }
  @include hoodq-logo-shared;
}}

@media only screen and (min-width: 577px) and (max-width: 991px) {
  div.content-container {
    margin-top: 4.5rem !important;
    div.row {
      div.logo-container {
        display: none;
      }
    }
  }
  nav {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    div.d-md-none, span.navbar-brand.d-md-none {
      display: inline !important;
      margin-left: 1rem;
    }
    div.d-none.d-md-block {
      a.navbar-brand.logo-container {
        display: none;
      }
    }
    button.navbar-toggler {
      margin-right: 1rem;
    }
  }
  div.row#search-address {
    div.col {
      padding-top: 4rem;
    }
  }
}

@media only screen and (max-width: 991px) {
  nav {
    #navbar-container {
      height: 100vh !important;
      overflow: scroll;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  svg#hoodq-logo-search-address {
    margin-top: 2.5rem;
  }
  div.row#search-address {
    div.col {
      padding-top: 3rem;
    }
  }
}

