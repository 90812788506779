body.detailed-report {
  .container {
    max-width: 900px;
  }
}

.detailed-report {
  @extend .address-report;
  
  h3 {
    text-transform: initial !important;
    display: inline;
  }
}