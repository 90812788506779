.users-setup {
  .main-app-container {
    .nav-container {
      @extend .d-none;
    }

    .content-container {
      @extend .col-12;
      @extend .col-md-10;
      @extend .offset-md-1;
      @extend .mt-5;
      @extend .p-5;
      @extend .text-center;

      @include media-breakpoint-up(lg) {
        border: 2px solid $gray-700;
        border-radius: 60px;
        border-top-left-radius: 20px;
      }

      .logo-container {
        @extend .w-100;
        @extend .mb-5;
        height: 135px;

        svg {
          width: 150px;
        }
      }

      .form-container {
        @extend .mt-5;
        @extend .col-12;
        @extend .col-lg-8;
        @extend .offset-lg-2;

        button.btn {
          @extend .w-100;
        }
      }

      .banner-preview-copy {
        @extend .my-4;
      }

      .address-report {
        @extend .p-2;
        text-align: initial;
        border-top: 1px solid $gray-700;
        border-left: 1px solid $gray-700;
        border-right: 1px solid $gray-700;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 110px;
        box-shadow: 0px 0px 5px $gray-700;

        .address-report-overlay {
          position: relative;
          top: -110px;
          margin-left: -10px;
          width: 105%;
          height: 110px;
          background-image: linear-gradient(rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 1) 90%)
        }

        .report-banner {
          position: relative;
        }
      }
    }
  }
}