@import "./library/variables";
@import "./library/mixins";

$hq-font-path: "scss/library/fonts";
@import "./library/fonts";

@import "./library/reports";

// $facility-icons: (
//   "pool": inline_svg('images/svg/pool.svg')
// );

@each $name, $color in $dataset-colors {
  .text-#{$name} {
    color: $color;
    -webkit-print-color-adjust: exact;
  }
}

.text-gray {
  color: $gray;
}

.nav.nav-pills {
  .nav-item {
    margin: 2px 0;
  }
}