.highlights { /* stylelint-ignore */
}

/* NOTE : this avoids urls to be printed in full */
.highlights a:link::after,
.highlights a:visited::after {
  content: '';
}

.highlights hr {
  margin: 10px 0;
  border-top: 2px solid #8A8C8C;
}

.highlights h5 {
  font-size: 15px;
}

@media print {
  .highlights {
    min-width: 800px !important;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .highlights::-webkit-scrollbar,
  .highlights > *::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}


.row {
  border-bottom: 2px solid #8A8C8C;
  padding: 10px 0;
}

.row > div {
  margin: 0 0px;
}

.highlights > div.row {
  border-bottom: 2px solid #8A8C8C;
  /* padding: 10px 0; */
  /* margin: auto 0; */
}

.highlights > div > section:first-child {
  /* padding-top: 0; */
  /* margin-top: -10px; */
}

@media print {
  .highlights > div > section:last-child {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.quadrantsRow {
  display: table !important;
}

@media print {
  .quadrantsRow {
    display: block !important;
  }
}

.quadrantsRow > div:last-child {
  border-right: 0 !important;
  border-bottom: 0 !important;
}

@media print {
  .quadrantsRow > div {
    border-right: 2px solid #8A8C8C !important;
    display: table-cell;
    float: none !important;
  }
}

@media screen and (max-width: 767px) {
  /* NOTE : This styling gets replaced with sidebar.css & nosidebar.css
   * styling sheets from View component
   */
  /* .quadrantsRow > div {
    border-bottom: 2px solid #8A8C8C;
    padding-top: 10px;
    padding-bottom: 10px;
  } */

  .quadrantsRow > div:first-child {
    padding-top: 0;
  }
}

.row > div {
  padding: 0;
}