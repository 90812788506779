//@font-face {
//  font-family: 'Museo-700';
//  src: url('./fonts/2B79A2_1_0.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'AvenirNext-Medium';
//  src: url('./fonts/2BB11F_0_0.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'AvenirNext-Bold';
//  src: url('./fonts/2B79A2_3_0.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'Avenir-Black';
//  src: url('./fonts/2B79A2_2_0.woff') format('woff');
//}
//
//html, body {
//  margin: 0;
//}

// HOMEBUYER HUB STYLING
.homebuyer-hub-search-bar {
  .rbt-input-main {
    border-radius: 0 !important;
    border-color: #fff;
    background-color: #fff !important;
    color: $dark-blue !important;

    &::placeholder {
      color: $dark-blue;
    }
  }

  .rbt-input-hint {
    color: $dark-blue !important;
  }

  .rbt-menu {
    border-radius: 0 !important;
  }
}

