@mixin content-container-zero-padding {
  div.container-fluid.main-app-container {
    div.row#main-row {
      padding: 0 !important;
      div.content-container {
        padding: 0 !important;
        min-width: 100% !important;
      }
    }
  }
}

// @page is not supported by Safari
@page {
  size: auto;
  margin: 20px;
}

@media print {

  .main-app-container {
    height: initial !important;
    margin-top: 20px;
  }

  .school-report .container {
    min-width: 0 !important;
    max-width: initial !important;
  }

}

/* Works to target Safari 14.1+ */
@supports(font:-apple-system-body) and (not(-webkit-touch-callout:default)){
  @media print {
    @include content-container-zero-padding;
  }
}

/* Safari 11 to 15.6 - Needed for 13.1 */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  @media print {
    @include content-container-zero-padding;
  }
}}


@-moz-document url-prefix() {
  @media print {
    div.footer.row {
      position: relative;
      top: 69rem;
    }

    // The police icon is turned off in Firefox for printing, since it's never
    // placed to the left of the 'The nearest police facility ...' text, but below
    // it or above it, no matter how the 'top' property is adjusted.
    div[class^="row SafetyReport__styles__safetyReport"] {
      div[class^="DetailedPlaceBlock__styles__detailedPlaceBlock"]:last-of-type {
        div[class^="DetailedPlaceBlock__styles__icon"] svg {
          display: none;
        }
      }
    }
  }
}