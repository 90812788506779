@import "./scss/library/variables";
@import "~bootstrap/scss/bootstrap";
@import "./scss/library";

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/v4-shims';

// @import "./scss/fonts";
@import "./scss/focused_form";
@import "./scss/navigation";
@import "./scss/packages";
@import "./scss/sessions";
@import "./scss/components/progress_bar";
@import "./scss/print";
@import "./scss/products";

@import "./scss/products/address_report";
@import "./scss/products/school_report";
@import "./scss/products/detailed_report";
@import "./scss/products/open_house_report";

.bg-dark-blue {
  background-color: $dark-blue !important;
}

.text-dark-blue {
  color: $dark-blue !important;
}

.text-black {
  color: black !important;
}

.carousel-control-prev, .carousel-control-next {
  color: $white !important;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  background-size: 75% !important;
  background-color: $primary !important;
}

.place-toggle {
  background-color: $white;
  color: $primary;
  border: 0;
  position: absolute;
  right: 0;
}

.dev-tools {
  @extend .fixed-top;
  @extend .p-3;
  @extend .w-25;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
body {
  font-family: AvenirNextLTPro-Regular !important;
  min-width: 320px;
  font-size: 14px;

  &.product {
    background-color: $white;
  }
  
  @media print {
    min-width: 970px !important;
  }
}

a {
  color: $primary;
}

h1, h2, h3, h4, h5, strong, label {
  font-family: AvenirNextLTPro-Medium;
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}


h5 {
  font-size: 15px;
}

body {
  .main-app-container {
    max-width: 1200px;
    overflow: visible;
    height: calc(100vh - 50px);

    @media print {
      max-width: initial;
    }

    .nav-container {
      @extend .col-12;
      @extend .col-lg-3;
      @extend .d-print-none;
      z-index: 10;

      .rbt-menu {
        border-radius: 0 !important;

        .dropdown-item {
          white-space: initial !important;
        }
      }

      @include media-breakpoint-up(md) {
        background-color: $white;

        nav {
          padding-left: 0;
          padding-right: 0;
        }

        nav, .navbar-collapse {
          flex-direction: column;
        }

        .navbar-collapse {
          align-items: stretch;
        }
      }

      @include media-breakpoint-down(md) {
        position: fixed;
        padding: 0;
        border-bottom: 1px solid $gray-500;
        box-shadow: 0 2px 5px $gray-500;
        //background-color: $gray-100;

        .navbar-brand {
          color: $gray-500;
          font-weight: bold;
        }

        .navbar-toggler {
          background-color: $primary;
          border-color: $primary;
          border-radius: 30px;
        }
      }


    }

    .content-container {
      @extend .col-12;
      @extend .col-lg-9;

      @media print {
        margin-top: 0px !important;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 40px;
      }
    }
  }

  &.hide-nav {
    .main-app-container {
      .nav-container {
        @extend .d-none;
      }

      .content-container {
        @extend .col-12;
      }
    }
  }
}


.watermark {
  @extend .font-museo-bold;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 100px;
  left: 15px;
  font-size: 114px;
  color: #B2B4B2;
  z-index: 100;
  overflow: hidden;

  .watermark-text {
    margin-bottom: 10%;
    transform: rotate(-25deg);
    opacity: 0.46;
  }

  .watermark-text:nth-of-type(even) {
    margin-left: -25%;
  }

  .watermark-text:nth-of-type(odd) {
    margin-left: 45%;
  }
}

.logo-container {
  height: 100px;
  width: 100px;

  svg.logo {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.users-board_registration {
  li {
    @extend .my-2;
  }
}

.tooltip-inner {
  @extend .p-2;
  max-width: 400px;
  background-color: $lookbook-blue-4;
  opacity: 0.9;
  font-size: 12px;
  border-radius: 10px;
}

@mixin place-content($color) {
  h5 {
    color: $color;
  }

  .attributes {
    display: table;

    .attribute {
      display: table-row;
      padding: 2px;

      span {
        display: table-cell;
        border-bottom: 1px dashed #B3B4B3;
      }
    }
  }
}

@each $name, $color in $dataset-colors {
  .hoverable-#{$name} {
    @include hoverable-content($color: $color);
    @include place-content($color);

    .mobile-expander-button-container {
      @extend .d-block;
      @extend .d-sm-none;
    }

    @include media-breakpoint-down(md) {
      .regular-info, .expanded-info {
        width: 90%;
      }
    }
  }
}

// @each $name, $path in $facility-icons {
//   .icon-#{$name} {
//     @include facility-icon($name, $size: 25px);
//   }
// }

.font-weight-medium {
  font-family: AvenirNextLTPro-Medium;
}

.font-weight-bold {
  font-family: AvenirNextLTPro-Bold;
  font-weight: 400 !important;
}

.border-2px {
  border-width: 2px !important;
}

.border-gray {
  border-color: $gray !important;
}

.facility-icon {
  width:  25px;
  height: 25px;
  display: inline-block;

  svg {
    width: 25px;
    height: 25px;
  }
}

.walk-time-icon {
  width:  50px;
  height: 50px;
  text-align: center;
  color: $gray;

  svg {
    width: 35px;
    height: 35px;
    margin: 0 auto;
    fill: $gray;
    display: block;
  }
}

.footer {
  .small {
    font-size: 10px;
    font-family: AvenirNextLTPro-Cn;
    line-height: 14px;
  }

  img.logo {
    max-width: 100%;
    max-height: 40px;
  }
}

// Controller/action-based stylesheets.
@import "./scss/users/new";
@import "./scss/users/setup";
@import "./scss/products/new";
