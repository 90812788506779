//////////////////////////////////////////////////
// Fonts
//////////////////////////////////////////////////
@font-face {
  font-family: 'MuseoSlab-300';
  src: url(#{$hq-font-path}/2B79A2_0_0.woff) format('woff');
}

@font-face {
  font-family: 'MuseoSlab-500';
  src: url(#{$hq-font-path}/2B79A2_1_0.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url(#{$hq-font-path}/2B79A2_2_0.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url(#{$hq-font-path}/2B79A2_3_0.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Medium';
  src: url(#{$hq-font-path}/2BB11F_0_0.woff) format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Cn';
  src: url(#{$hq-font-path}/2C7923_0_0.woff) format('woff');
}


$fonts: (
  avenir: (
    "condensed": "AvenirNextLTPro-Cn",
    "regular":   "AvenirNextLTPro-Regular",
    "medium":    "AvenirNextLTPro-Medium",
    "bold":      "AvenirNextLTPro-Bold"
  ),

  museo: (
    "light":      "MuseoSlab-300",
    "regular":    "MuseoSlab-500"
  )
);

@each $family, $variants in $fonts {
  @each $style, $name in $variants {
    .font-#{$family}-#{$style} {
      font-family: $name;
    }
  }
}

.font-museo-bold {
  @extend .font-museo-regular;
  font-weight: 700;
}
