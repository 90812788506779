// Place all the styles related to the Packages controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

html, body {
  font-weight: normal;
  line-height: 20px;
}

.logo-container {
  text-align: center;
}

svg.logo {
  width: 157px;
  max-height: 143px;
  margin-top: 15%;
  margin-bottom: 40px;
}

h3 {
  font-size: 24px;
}